import React from 'react'
import { FormControlLabel, IconButton, Tooltip, Typography } from '@material-ui/core'

import Accordion from '_/components/accordion'
import SwitchComponent from '_/components/switch'

import { MoneyInput } from '@refera/ui-web'

import useStyles from './styles'
import { useSelector } from 'react-redux'
import { agencySelector } from '_/modules/agency/selectors'
import useRolePermission from '_/hooks/use-role-permission'
import Svg from '_/components/svg'
import QuestionIcon from '_assets/svgs/question-circle-blue.svg'

export const AgencyBypass = ({ isEditing, ...params }) => {
  const styles = useStyles()
  const { isAdmin } = useRolePermission()

  const agency = useSelector(agencySelector)

  return (
    <Accordion title="Parâmetros da empresa" className={styles.accordion}>
      {isAdmin && (
        <>
          <div>
            <Typography component="h3" className={styles.label}>
              Bypass revisão de orçamento
            </Typography>
            <FormControlLabel
              className={styles.switch}
              control={
                <SwitchComponent
                  name="bypass"
                  checked={params?.values?.bypass}
                  onChange={e => params.setFieldValue('bypass', e.target.checked)}
                  disabled={!isEditing}
                />
              }
            />
          </div>
          <div>
            <Typography component="h3" className={styles.label}>
              Valor mínimo para bypass de orçamento
            </Typography>
            <MoneyInput
              disabled={!isEditing || !params?.values?.bypass}
              allowNegative={false}
              allowLeadingZeros={false}
              placeholder="R$ "
              decimalScale={2}
              name="minBypassValue"
              label=""
              value={params?.values?.minBypassValue}
              onChange={e => params.setFieldValue('minBypassValue', e.floatValue)}
              style={{ fontSize: '14px' }}
            />
            {params.errors?.minBypassValue && (
              <Typography className={styles.error}>{params.errors?.minBypassValue}</Typography>
            )}
          </div>
          <div>
            <Typography component="h3" className={styles.label}>
              Valor máximo para bypass de orçamento
            </Typography>
            <MoneyInput
              disabled={!isEditing || !params?.values?.bypass}
              allowNegative={false}
              allowLeadingZeros={false}
              placeholder="R$ "
              decimalScale={2}
              name="maxBypassValue"
              label=""
              value={params?.values?.maxBypassValue}
              onChange={e => params.setFieldValue('maxBypassValue', e.floatValue)}
              style={{ fontSize: '14px' }}
            />
            {params.errors?.maxBypassValue && (
              <Typography className={styles.error}>{params.errors?.maxBypassValue}</Typography>
            )}
          </div>
        </>
      )}

      {agency?.contractType === 'SAAS' && (
        <div>
          <Typography component="h3" className={styles.label}>
            NF exigida na finalização
            <Tooltip
              title="Se este campo estiver desmarcado, significa que a Nota fiscal NÃO será exigida para que um chamado seja finalizado. Desde que tal chamado não esteja sendo atendido pela Refera."
              placement="bottom"
              PopperProps={{ style: { marginTop: -7 } }}
            >
              <IconButton>
                <Svg icon={QuestionIcon} className={styles.supportIcon} />
              </IconButton>
            </Tooltip>
          </Typography>
          <FormControlLabel
            className={styles.switch}
            defaultValue={params?.values?.nfRequired ?? true}
            control={
              <SwitchComponent
                name="nfRequired"
                checked={params?.values?.nfRequired}
                onChange={e => params.setFieldValue('nfRequired', e.target.checked)}
                disabled={!isEditing}
              />
            }
          />
        </div>
      )}
    </Accordion>
  )
}
