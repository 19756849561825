import React, { useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useLocation, navigate } from '@reach/router'

import { getUserLoadingSelector, userSelector } from '_modules/authentication/selectors'
import { companySelector } from '_/modules/company/selector'
import { getUser, getVerificationToken, disableApplication } from '_modules/authentication/actions'
import Header from '_components/header'
import Loading from '_components/loading'
import SideMenu from '_components/side-menu'
import useRolePermission from '_hooks/use-role-permission'
import { useLocalStorage } from '_hooks/use-local-storage'
import { ROUTES } from '_utils/constants'
import { HEADER_TAGS } from '_utils/header-tags'

import useStyles from './styles'
import RequestTaxRegimeModal from '_/components/request-tax-regime'
import { getCompany } from '_/modules/company/actions'
import { AcceptPrivacyPolicyModal } from '_/components/modal/accept-privacy-policy-modal'
import { ContractModalManager } from '_/components/modal/contracts'
import { showContractModalManagerSelector } from '_/modules/utils/selectors'
import { toggleShowContractModalManager } from '_/modules/utils/actions'
import { useToast } from '_/hooks/use-toast'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'

const SETUP_FORM_PATHNAME = '/solicitar-manutencao'
const ROUTES_TO_HIDE_SIDEBAR = [ROUTES.SERVICE_ORDER, ROUTES.LANDING, ROUTES.LANDING_PAGE_B2C]
const OPEN_ROUTES = [ROUTES.LANDING_PAGE_B2C]

const App = ({ children }) => {
  const { pathname } = useLocation()
  const showSideBar = useMemo(() => !ROUTES_TO_HIDE_SIDEBAR.includes(pathname), [pathname])
  const styles = useStyles({ showSideBar })
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const company = useSelector(companySelector)
  const isUserLoading = useSelector(getUserLoadingSelector)
  const { hasPermissionToAccessPage, isTradesman, checkIfUserDoesNotPermission } =
    useRolePermission()
  const [hasPendingData, setHasPendingData] = useLocalStorage('hasPendingData', null)
  const showContractModalManager = useSelector(showContractModalManagerSelector)
  const [isAplicationOff, setIsAplicationOff] = useLocalStorage('isApplicationOff', null)
  const { showToast } = useToast()

  const isLoadingUser = useMemo(() => {
    return isUserLoading || !user?.id
  }, [isUserLoading, user?.id])

  const handleCloseModal = useCallback(() => {
    setHasPendingData(false)
  }, [])

  const handleShowAccessError = useCallback(() => {
    return showToast({
      type: 'error',
      message:
        'Você não tem permissão para visualizar os dados desta área da aplicação. Por favor, fale com seu administrador.',
    })
  }, [])

  useEffect(() => {
    if (hasPermissionToAccessPage === false && user?.id) {
      handleShowAccessError()
    }
  }, [hasPermissionToAccessPage, user?.id, pathname])

  useEffect(() => {
    if (!user.id) {
      dispatch(disableApplication()).then(res => {
        const applicationOffValue = res?.results?.reduce((acc, obj) => {
          if (obj.name === 'web_backoffice') {
            return obj.applicationOff
          }
          return acc
        }, null)

        setIsAplicationOff(applicationOffValue)
      })
    }
  }, [dispatch, user])

  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (!user.id && !isAplicationOff) {
          await dispatch(getUser())
        }

        if (user?.id && !user.termsAccepted) {
          console.warn('Não aceitou os termos')
        }
      } catch (error) {
        console.error('Erro ao buscar usuário:', error)
      }
    }

    fetchUser()
  }, [dispatch, user, isAplicationOff])

  useEffect(() => {
    if (isTradesman && !Object.keys(company).length) {
      dispatch(getCompany())
    }
    if (isTradesman && !user.verificationToken) {
      dispatch(getVerificationToken())
    }
  }, [isTradesman])

  useEffect(() => {
    if (isTradesman && hasPendingData === null && company?.taxRegime === null) {
      setHasPendingData(true)
    }
  }, [isTradesman, company?.taxRegime])

  // Now the ifs will be inside a useCallback function to avoid unnecessary re-renders
  const checkPage = useCallback(() => {
    if (!isUserLoading && user?.id && !isAplicationOff && !OPEN_ROUTES.includes(pathname)) {
      if (!user.authToken) {
        localStorage.setItem('target-url', window.location.href)
        return navigate(ROUTES.LOGIN)
      }

      if (company?.redirectToLandingPage && !pathname.includes(ROUTES.LANDING)) {
        return navigate(ROUTES.LANDING)
      }

      if (hasPermissionToAccessPage === false && isTradesman) {
        return navigate(ROUTES.TRADESMAN)
      }

      if (
        pathname.includes(ROUTES.BUDGET) &&
        checkIfUserDoesNotPermission(
          PERMISSIONS.BUDGETS,
          [PERMISSIONS_ACTIONS.READ, PERMISSIONS_ACTIONS.CREATE, PERMISSIONS_ACTIONS.UPDATE],
          undefined
        )
      ) {
        return navigate(ROUTES.ACCESS_DENIED)
      }

      if (
        pathname.includes(ROUTES.BUDGET_VIEW) &&
        checkIfUserDoesNotPermission(
          PERMISSIONS.ORIGINAL_BUDGETS,
          [PERMISSIONS_ACTIONS.READ, PERMISSIONS_ACTIONS.CREATE, PERMISSIONS_ACTIONS.UPDATE],
          undefined
        )
      ) {
        return navigate(ROUTES.ACCESS_DENIED)
      }

      if (hasPermissionToAccessPage === false && !pathname.includes(SETUP_FORM_PATHNAME)) {
        return navigate(ROUTES.ACCESS_DENIED)
      }
    }
    if (!isUserLoading && !user?.id && !user?.authToken) {
      localStorage.setItem('target-url', window.location.href)
      return navigate(ROUTES.LOGIN)
    }

    return null
  }, [
    hasPermissionToAccessPage,
    isUserLoading,
    user?.id,
    user?.authToken,
    company,
    isAplicationOff,
    pathname,
  ])

  useEffect(() => {
    checkPage()
  }, [checkPage])

  if (isLoadingUser) {
    return <Loading />
  }

  if (isAplicationOff) {
    const systemPrevisionDate = new Date(isAplicationOff)
    return <Redirect noThrow to="/system-maintenance" state={{ systemPrevisionDate }} />
  }

  return (
    <>
      <Grid className={styles.app}>
        {HEADER_TAGS[ROUTES.MANAGE_ORDERS].header}
        {!company?.redirectToLandingPage && isTradesman && (
          <>
            <RequestTaxRegimeModal open={hasPendingData} closeModal={handleCloseModal} />
            {showContractModalManager && (
              <ContractModalManager onFinish={() => dispatch(toggleShowContractModalManager)} />
            )}
          </>
        )}
        <Header className={styles.header} />
        {showSideBar && <SideMenu className={styles.sidebar} />}
        <Grid className={styles.children}>{children}</Grid>
      </Grid>
      {!user?.termsAccepted && <AcceptPrivacyPolicyModal />}
    </>
  )
}

App.propTypes = {
  children: PropTypes.node,
}

App.defaultProps = {
  children: null,
}

export default React.memo(App)
