import { TEXTFIELD_MASK } from '_components/textfield'
import { validationDate, validationCPF, parseNumber, validationCNPJ } from '_utils/helpers'
import { BANK_ACCOUNT_TYPE_OPTIONS, KEY_PIX_OPTIONS } from '_utils/constants'

export const AGENCY_FIELDS = {
  ADDRESS: 'address',
  ATTACHMENTS: 'agencyAttachments',
  STREET_NUMBER: 'streetNumber',
  BANK: 'bank',
  BANK_ACCOUNT: 'bankAccount',
  BANK_ACCOUNT_CPF_CNPJ: 'bankAccountCpfCnpj',
  BANK_ACCOUNT_OWNER_NAME: 'bankAccountOwnerName',
  BANK_ACCOUNT_TYPE: 'bankAccountType',
  BANK_ACCOUNT_NUMBER: 'bankAccountNumber',
  BANK_ACCOUNT_WITH_DIGIT: 'bankAccountNumber',
  BANK_AGENCY_NUMBER: 'bankAgencyNumber',
  BYPASS: 'bypass',
  MIN_BYPASS_VALUE: 'minBypassValue',
  MAX_BYPASS_VALUE: 'maxBypassValue',
  CEP: 'cep',
  CITY: 'city',
  EXTRA_ADDRESS_INFO: 'extraAddressInfo',
  CPF_CNPJ: 'cpfCnpj',
  AGENCY_CREATION_DATE: 'agencyCreationDate',
  CONTACT_EMAIL: 'contactEmail',
  NEARBY_LANDMARK: 'nearbyLandmark',
  NEIGHBORHOOD: 'neighborhood',
  PERSON_RESPONSIBLE_NAME: 'personResponsibleName',
  PHONE_NUMBER: 'phoneNumber',
  PIX_KEY: 'pixKey',
  PIX_KEY_CHOICE: 'pixKeyChoice',
  PROFILE_PICTURE: 'profilePicture',
  NAME: 'name',
  IS_ACTIVE: 'isActive',
  STATE: 'state',
  CONTRACT_SOCIAL_STATUTE: 'contractSocialStatute',
  BRAND_LOGO_PNG_URL: 'brandLogoPngUrl',
  REGISTRATION_STATUS: 'registrationStatus',
  MAIN_SERVICE_NAMES: 'mainServiceNames',
  STAFF_USERS: 'staffUsers',
  RESPONSIBLE_REFERA: 'responsibleRefera',
  RESPONSIBLE_REFERA_SECTORS: 'responsibleReferaSectors',
  SUPPORT_PHONE: 'supportPhone',
  SUPPORT_WHATSAPP: 'supportWhatsapp',
  CONTRACT_TYPE: 'contractType',
  NF_REQUIRED: 'nfRequired',
}

export const INITIAL_STATE = Object.values(AGENCY_FIELDS).reduce((acc, value) => {
  if (value === AGENCY_FIELDS.BYPASS || value === AGENCY_FIELDS.NF_REQUIRED) {
    return {
      ...acc,
      [value]: true,
    }
  }

  if (value === AGENCY_FIELDS.MIN_BYPASS_VALUE) {
    return {
      ...acc,
      [value]: 50,
    }
  }

  if (value === AGENCY_FIELDS.MAX_BYPASS_VALUE) {
    return {
      ...acc,
      [value]: 750,
    }
  }

  return {
    ...acc,
    [value]: '',
  }
}, {})

const SITUATION = [
  { label: 'Ativa', value: 'true' },
  { label: 'Desativada', value: 'false' },
]

export const PROFILE_SECTION_FIELDS = [
  {
    name: AGENCY_FIELDS.NAME,
    label: 'Nome da intermediária',
    placeholder: 'Digite o nome',
  },
  {
    name: AGENCY_FIELDS.PERSON_RESPONSIBLE_NAME,
    label: 'Nome do responsável',
    placeholder: 'Digite o nome',
  },
  { name: AGENCY_FIELDS.CONTACT_EMAIL, label: 'Email', placeholder: 'email@email.com' },
]

export const AGENCY_VIEW_VALUES = [
  {
    title: 'Dados da empresa',
    id: 'dados-da-empresa-1',
    fields: [
      {
        name: AGENCY_FIELDS.CPF_CNPJ,
        label: 'CPF/CNPJ',
        placeholder: '00.000.000/0001-00',
        isCpfCnpj: true,
      },
      {
        name: AGENCY_FIELDS.PHONE_NUMBER,
        label: 'Telefone',
        placeholder: '(XX) 0 0000-0000',
      },
      {
        name: AGENCY_FIELDS.AGENCY_CREATION_DATE,
        label: 'Data de abertura',
        mask: TEXTFIELD_MASK.DATE,
        placeholder: 'DD/MM/AAAA',
      },
      {
        name: AGENCY_FIELDS.CONTRACT_SOCIAL_STATUTE,
        label: 'Contrato / Estatuto social',
        placeholder: '00000000000000',
      },
      {
        name: AGENCY_FIELDS.SUPPORT_PHONE,
        label: 'Suporte por telefone',
        mask: TEXTFIELD_MASK.PHONE_NUMBER,
        placeholder: '(XX) 0 0000-0000',
      },
      {
        name: AGENCY_FIELDS.SUPPORT_WHATSAPP,
        label: 'Suporte por whats',
        placeholder: '(XX) 0 0000-0000',
      },
      {
        name: AGENCY_FIELDS.IS_ACTIVE,
        label: 'Situação',
        options: SITUATION,
      },
    ],
  },
  {
    title: 'Endereço da empresa',
    id: 'endereco-da-empresa-1',
    fields: [
      { name: AGENCY_FIELDS.ADDRESS, label: 'Endereço', placeholder: 'Digite o endereço' },
      { name: AGENCY_FIELDS.STREET_NUMBER, label: 'Número', placeholder: '0000' },
      {
        name: AGENCY_FIELDS.CEP,
        label: 'CEP',
        mask: TEXTFIELD_MASK.CEP,
        placeholder: 'Digite o CEP',
      },
      {
        name: AGENCY_FIELDS.EXTRA_ADDRESS_INFO,
        label: 'Complemento',
        placeholder: 'Digite o complemento',
      },
      { name: AGENCY_FIELDS.NEIGHBORHOOD, label: 'Bairro', placeholder: 'Digite o Bairro' },
      { name: AGENCY_FIELDS.CITY, label: 'Cidade', placeholder: 'Digite a Cidade' },
      {
        name: AGENCY_FIELDS.NEARBY_LANDMARK,
        label: 'Ponto de referência',
        placeholder: 'Ponto de Referência',
      },
      { name: AGENCY_FIELDS.STATE, label: 'Estado', placeholder: 'Estado' },
    ],
  },
  {
    title: 'Dados bancários',
    id: 'dados-bancarios-1',
    fields: [
      {
        name: AGENCY_FIELDS.BANK_ACCOUNT_OWNER_NAME,
        label: 'Nome completo do titular da conta',
        placeholder: 'Digite o nome completo aqui',
      },
      {
        name: AGENCY_FIELDS.BANK_ACCOUNT_TYPE,
        label: 'Operação da conta',
        placeholder: 'Selecione o tipo de conta',
        options: BANK_ACCOUNT_TYPE_OPTIONS,
      },
      {
        name: AGENCY_FIELDS.BANK,
        label: 'Banco',
        placeholder: 'Selecione o banco',
        options: [],
      },
      {
        name: AGENCY_FIELDS.BANK_ACCOUNT_CPF_CNPJ,
        label: 'CPF/CNPJ',
        isCpfCnpj: true,
        placeholder: '000.000.000-00',
      },
      {
        name: AGENCY_FIELDS.BANK_AGENCY_NUMBER,
        label: 'Número da agência',
        placeholder: '0000',
        mask: TEXTFIELD_MASK.AGENCY,
      },
      {
        name: AGENCY_FIELDS.BANK_ACCOUNT_WITH_DIGIT,
        label: 'Número da conta',
        placeholder: '0000000000-0',
        mask: TEXTFIELD_MASK.BANK_WITH_DIGIT,
      },
      {
        name: AGENCY_FIELDS.PIX_KEY_CHOICE,
        label: 'Chave PIX',
        placeholder: 'Selecione a chave pix',
        options: KEY_PIX_OPTIONS,
      },
      { name: AGENCY_FIELDS.PIX_KEY, label: 'Chave', placeholder: 'Digite a chave pix' },
    ],
  },
]

const REQUIRED_FIELDS = [
  AGENCY_FIELDS.PERSON_RESPONSIBLE_NAME,
  AGENCY_FIELDS.NAME,
  AGENCY_FIELDS.CONTACT_EMAIL,
  AGENCY_FIELDS.CPF_CNPJ,
]

export const validate = values => {
  const errors = {}

  REQUIRED_FIELDS.map(value => {
    if (!values[value]) {
      errors[value] = 'Campo obrigatório'
    }
    return null
  })

  if (
    values[AGENCY_FIELDS.AGENCY_CREATION_DATE] &&
    !validationDate(values[AGENCY_FIELDS.AGENCY_CREATION_DATE])
  ) {
    errors[AGENCY_FIELDS.AGENCY_CREATION_DATE] = 'Data inválida'
  }

  if (values[AGENCY_FIELDS.BANK_ACCOUNT_CPF_CNPJ]?.length) {
    const cpfCnpj = parseNumber(values[AGENCY_FIELDS.BANK_ACCOUNT_CPF_CNPJ])

    if (cpfCnpj.length !== 11 && cpfCnpj.length !== 14) {
      errors[AGENCY_FIELDS.BANK_ACCOUNT_CPF_CNPJ] = 'Valor não válido'
    }
    if (cpfCnpj.length === 11 && !validationCPF(cpfCnpj)) {
      errors[AGENCY_FIELDS.BANK_ACCOUNT_CPF_CNPJ] = 'CPF inválido'
    }
    if (cpfCnpj.length === 14 && !validationCNPJ(cpfCnpj)) {
      errors[AGENCY_FIELDS.BANK_ACCOUNT_CPF_CNPJ] = 'CNPJ inválido'
    }
    if (cpfCnpj.length > 14) {
      errors[AGENCY_FIELDS.BANK_ACCOUNT_CPF_CNPJ] = 'Valor não válido'
    }
  }

  if (values[AGENCY_FIELDS.CNPJ]?.length) {
    const cpfCnpj = parseNumber(values[AGENCY_FIELDS.CPF_CNPJ])

    if (cpfCnpj.length !== 11 && cpfCnpj.length !== 14) {
      errors[AGENCY_FIELDS.CNPJ] = 'Valor não válido'
    }
    if (cpfCnpj.length === 11 && !validationCPF(cpfCnpj)) {
      errors[AGENCY_FIELDS.CNPJ] = 'CPF inválido'
    }
    if (cpfCnpj.length === 14 && !validationCNPJ(cpfCnpj)) {
      errors[AGENCY_FIELDS.CNPJ] = 'CNPJ inválido'
    }
  }

  if (!values[AGENCY_FIELDS.PERSON_RESPONSIBLE_NAME]?.length) {
    errors[AGENCY_FIELDS.PERSON_RESPONSIBLE_NAME] = 'Insira um nome de responsável'
  }
  if (!values[AGENCY_FIELDS.NAME]?.length) {
    errors[AGENCY_FIELDS.NAME] = 'Insira um nome'
  }

  if (!values[AGENCY_FIELDS.CONTACT_EMAIL]?.length) {
    errors[AGENCY_FIELDS.CONTACT_EMAIL] = 'Insira um email'
  }
  if (!values[AGENCY_FIELDS.CPF_CNPJ]?.length) {
    errors[AGENCY_FIELDS.CPF_CNPJ] = 'Insira um CPF/CNPJ'
  }

  if (values[AGENCY_FIELDS.BYPASS]) {
    const maxBypassValue = Number(values[AGENCY_FIELDS.MAX_BYPASS_VALUE])
    const minBypassValue = Number(values[AGENCY_FIELDS.MIN_BYPASS_VALUE])

    if (!maxBypassValue && maxBypassValue !== 0) {
      errors[AGENCY_FIELDS.MAX_BYPASS_VALUE] = 'Campo obrigatório'
    }

    if (maxBypassValue > 999999.99) {
      errors[AGENCY_FIELDS.MAX_BYPASS_VALUE] = 'Valor deve ser menor que R$ 999999,99'
    }

    if (minBypassValue > 999999.99) {
      errors[AGENCY_FIELDS.MIN_BYPASS_VALUE] = 'Valor deve ser menor que R$ 999999,99'
    }

    if (!minBypassValue && minBypassValue !== 0) {
      errors[AGENCY_FIELDS.MIN_BYPASS_VALUE] = 'Campo obrigatório'
    }
  }

  return errors
}
