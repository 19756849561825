import React, { useCallback, useState } from 'react'
import * as Modal from '_components/modal/generic-modal'
import { Loader } from '@refera/ui-web'

import useStyles from './styles'
import { FormProvider, useForm } from 'react-hook-form'
import { BasicInput, Select } from '_/components/inputs'
import { useDispatch, useSelector } from 'react-redux'
import { useToast } from '_/hooks/use-toast'
import { CRYPTO_TYPE } from './constants'
import { Grid } from '@mui/material'
import { createEmail, editEmail, testEmail } from '_/modules/email/actions'
import { agencySelector } from '_/modules/agency/selectors'
import { emailSelector } from '_/modules/email/selectors'
import { Attachments } from '_/components/refera-ui'

export default function EmailModal({ open, onClose }) {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { showToast } = useToast()
  const agency = useSelector(agencySelector)
  const email = useSelector(emailSelector)
  const [loading, setLoading] = useState(false)
  const [attachmentsBaseboard, setAttachmentsBaseboard] = useState(email?.baseboard)
  const [attachmentsHeader, setAttachmentsHeader] = useState(email?.header)

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      provider: email?.provider,
      smtpAddress: email?.smtpAddress,
      smtpPort: email?.smtpPort,

      encryptionMethod: email?.encryptionMethod,
      username: email?.username,
      password: email?.password,
      sendingDomain: email?.sendingDomain,
    },
  })

  const { handleSubmit, errors } = methods

  const onSubmit = useCallback(
    async data => {
      setLoading(true)
      dispatch(
        email?.id
          ? editEmail(agency.id, {
              ...data,
              provider: data?.provider?.toLowerCase(),
              header: attachmentsHeader?.[0],
              baseboard: attachmentsBaseboard?.[0],
            })
          : createEmail(agency.id, {
              ...data,
              provider: data?.provider?.toLowerCase(),
              header: attachmentsHeader?.[0],
              baseboard: attachmentsBaseboard?.[0],
            })
      )
        .then(() => {
          showToast({ type: 'success', message: 'Configuração de email salva com sucesso' })
          onClose()
        })
        .catch(error => {
          console.error(error)
          showToast({ type: 'error' })
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [onClose, agency, attachmentsHeader, attachmentsBaseboard]
  )

  const onTestSubmit = useCallback(
    async data => {
      setLoading(true)

      dispatch(testEmail(data))
        .then(() => {
          showToast({ type: 'success' })
        })
        .catch(error => {
          console.error(error)
          showToast({ type: 'error', message: 'Erro de conexão. Revise seus dados.' })
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [onClose, agency]
  )

  return (
    <Modal.Root open={open} onClose={onClose} maxWidth="md">
      <Modal.TitleModal title="Configuração de Email" />

      <Modal.Content className={styles.container}>
        <FormProvider {...methods}>
          <form className={styles.box} id="formEmail">
            <Grid className={styles.column}>
              <BasicInput
                label="Provedor de Serviço de Email"
                name="provider"
                defaultValue={email?.provider}
                labelClasses={styles.label}
                rules={{
                  maxLength: {
                    value: 255,
                    message: `Esse campo aceita no máximo 255 caracteres`,
                  },
                }}
              />
            </Grid>

            <Grid className={styles.column}>
              <BasicInput
                label="Endereço SMTP"
                name="smtpAddress"
                required
                defaultValue={email?.smtpAddress}
                labelClasses={styles.label}
                rules={{
                  maxLength: {
                    value: 255,
                    message: `Esse campo aceita no máximo 255 caracteres`,
                  },
                  required: { value: true, message: 'Esse campo é obrigatório' },
                }}
              />
            </Grid>

            <Grid className={styles.column}>
              <BasicInput
                label="Porta SMTP"
                name="smtpPort"
                defaultValue={email?.smtpPort}
                required
                labelClasses={styles.label}
                rules={{
                  maxLength: {
                    value: 255,
                    message: `Esse campo aceita no máximo 255 caracteres`,
                  },
                  required: { value: true, message: 'Esse campo é obrigatório' },
                }}
              />
            </Grid>
            <Grid className={styles.line}>
              <Grid className={styles.column}>
                <Select
                  label="Método de Criptografia"
                  name="encryptionMethod"
                  defaultValue={email?.encryptionMethod}
                  required
                  error={!!errors?.provider}
                  options={CRYPTO_TYPE}
                  getLabel={item => item.label}
                  getValue={item => item.value}
                  // defaultValue={agency?.provider}
                  // disabled={user?.activeProfile === 'intermediary'}
                  getKey={item => item.value}
                  menuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'center',
                    },
                  }}
                  style={{ gridArea: 'businessFront' }}
                  containerClasses={styles.select}
                />
              </Grid>
              <Grid className={styles.column}>
                <BasicInput
                  label="Nome de Usuário (Login)"
                  name="username"
                  defaultValue={email?.username}
                  required
                  labelClasses={styles.label}
                  rules={{
                    maxLength: {
                      value: 255,
                      message: `Esse campo aceita no máximo 255 caracteres`,
                    },
                    required: { value: true, message: 'Esse campo é obrigatório' },
                  }}
                />
              </Grid>
            </Grid>

            <Grid className={styles.line}>
              <Grid className={styles.column}>
                <BasicInput
                  label="Senha/API Key"
                  name="password"
                  defaultValue={email?.password}
                  required
                  labelClasses={styles.label}
                  rules={{
                    maxLength: {
                      value: 255,
                      message: `Esse campo aceita no máximo 255 caracteres`,
                    },
                    required: { value: true, message: 'Esse campo é obrigatório' },
                  }}
                  type="password"
                />
              </Grid>
              <Grid className={styles.column}>
                <BasicInput
                  label="Domínio de Envio"
                  name="sendingDomain"
                  defaultValue={email?.sendingDomain}
                  labelClasses={styles.label}
                  rules={{
                    maxLength: {
                      value: 255,
                      message: `Esse campo aceita no máximo 255 caracteres`,
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Grid className={styles.column}>
              <Attachments
                label="Cabeçalho padrão"
                files={attachmentsHeader || []}
                accept={{
                  'image/jpeg': ['.jpeg', '.png', '.jpg', '.bmp', '.flv'],
                }}
                multiple={false}
                onDrop={files => setAttachmentsHeader(files)}
                onRemoveItem={() => setAttachmentsHeader()}
                onItemClick={index => {
                  window.open(attachmentsHeader[index]?.file)
                }}
              />
            </Grid>
            <Grid className={styles.column}>
              <Attachments
                label="Rodapé padrão"
                files={attachmentsBaseboard || []}
                accept={{
                  'image/jpeg': ['.jpeg', '.png', '.jpg', '.bmp', '.flv'],
                }}
                multiple={false}
                onDrop={files => setAttachmentsBaseboard(files)}
                onRemoveItem={() => setAttachmentsBaseboard([])}
                onItemClick={index => {
                  window.open(attachmentsBaseboard[index]?.file)
                }}
              />
            </Grid>
          </form>
        </FormProvider>
      </Modal.Content>

      <Modal.Actions>
        <Modal.ButtonBlue form="formEmail" onClick={handleSubmit(onTestSubmit)} disabled={loading}>
          Testar
        </Modal.ButtonBlue>
        <Modal.ButtonRed onClick={onClose} disabled={loading}>
          Cancelar
        </Modal.ButtonRed>
        <Modal.ButtonFullBlue form="formEmail" onClick={handleSubmit(onSubmit)} disabled={loading}>
          Salvar
        </Modal.ButtonFullBlue>
      </Modal.Actions>
      <Loader hasBackdrop open={loading} />
    </Modal.Root>
  )
}
