import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  container: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: spacing(20),
    marginTop: spacing(30),

    [breakpoints.down('sm')]: {
      marginTop: spacing(20),
    },
  },
  box: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: spacing(30),
    marginTop: spacing(20),
  },
  line: {
    width: '100%',
    display: 'flex',
    gap: spacing(30),

    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: spacing(30),
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(10),
    width: '100%',
  },
  select: {
    width: '100%',
    margin: 0,
    height: '100% !important',
    marginTop: spacing(-15),

    '& .MuiFormLabel-root': {
      fontSize: `${spacing(16)} !important`,
      color: palette.gray[64],
    },

    '& .MuiFormHelperText-root': {
      display: 'none',
    },
  },
  label: {
    color: palette.gray[64],
    fontSize: spacing(12),
  },
}))
