export const CRYPTO_TYPE = [
  {
    label: 'TLS',
    value: 'tls',
  },
  {
    label: 'SSL',
    value: 'ssl',
  },
]
