import React, { useMemo, useCallback, useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useParams, navigate } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'

import useBoolean from '_hooks/use-toggle'
import { formatDateDays, reducerBankAccount } from '_utils/helpers'
import Accordion from '_components/accordion'
import Button from '_components/button'
import AccordionAddUser from '_components/accordion/add-users'
import AddAttachments from '_/components/accordion/add-attachments'
import useForm from '_hooks/use-form'
import AsideProfile from '_components/aside-profile'
import Svg, { ICON } from '_components/svg'
import { agencySelector } from '_modules/agency/selectors'
import {
  getAgencyFull,
  updateAgency,
  createAgency,
  deleteAgencyContract,
} from '_modules/agency/actions'
import IconButton from '_components/svg/icon-button'
import { banksLoadingSelector } from '_modules/utils/selectors'
import { ROUTES } from '_utils/constants'
import Loading from '_components/loading'
import AddCsrefera from '_components/accordion/add-csrefera'
import useRolePermission from '_hooks/use-role-permission'
import { AgencyBypass } from './agency-accordeons'
import { AgencyReferaDetailsModal } from './components/AgencyReferaDetailsModal'
import { getConnetionMessageTool } from '_/modules/provider/actions'

import { AGENCY_VIEW_VALUES, INITIAL_STATE, validate, AGENCY_FIELDS } from './constants'
import useStyles from './styles'
import { useToast } from '_/hooks/use-toast'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'
import EmailModal from './components/EmailModal'
import { getEmail } from '_/modules/email/actions'

const Agency = () => {
  const styles = useStyles()
  const { agencyId } = useParams()
  const dispatch = useDispatch()
  const [isEditing, handleEdit] = useBoolean(!agencyId)
  const agency = useSelector(agencySelector)
  const isBanksLoading = useSelector(banksLoadingSelector)
  const [isReferaDetailsModalOpen, setIsReferaDetailsModalOpen] = useState(false)
  const [isEmailProviderModalOpen, setIsEmailProviderModalOpen] = useState(false)
  const [attachments, setAttachments] = useState([])
  const [toDelete, setToDelete] = useState([])
  const { isAdmin, isIntermediary, isRefera, checkIfUserDoesNotPermission } = useRolePermission()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [gettingAgency, setGettingAgency] = useState(false)
  const { showToast } = useToast()

  const showBusinessParameters = useMemo(() => {
    if (isAdmin) return true

    if (agency?.contractType === 'SAAS') return true

    return false
  }, [isAdmin])

  useEffect(() => {
    if (agency?.id) {
      dispatch(getEmail(agency?.id))
    }
  }, [agency])

  const {
    errors,
    values,
    handleInputChange,
    touched,
    handleBlur,
    setFieldValue,
    setFieldValues,
    setTouchedAll,
  } = useForm(INITIAL_STATE, validate)

  const formHandleValues = useMemo(
    () => ({
      onChange: handleInputChange,
      onBlur: handleBlur,
      errors,
      touched,
      values,
      setFieldValue,
    }),
    [errors, handleBlur, handleInputChange, setFieldValue, touched, values]
  )

  let modifiedAgencyViewValues

  if (isIntermediary) {
    modifiedAgencyViewValues = AGENCY_VIEW_VALUES.map(item => {
      const isBankDataSection = item.title === 'Dados bancários'

      return {
        ...item,
        fields: item.fields.map(field => ({
          ...field,
          disabled:
            isBankDataSection ||
            field.name === AGENCY_FIELDS.CPF_CNPJ ||
            field.name === AGENCY_FIELDS.IS_ACTIVE,
        })),
      }
    })
  } else {
    modifiedAgencyViewValues = [...AGENCY_VIEW_VALUES]
  }

  const valueTransformations = {
    [AGENCY_FIELDS.IS_ACTIVE]: value => ({ [AGENCY_FIELDS.IS_ACTIVE]: value?.toString() }),
    [AGENCY_FIELDS.BANK_ACCOUNT]: value =>
      value !== null && value !== undefined ? reducerBankAccount(value) : {},
    [AGENCY_FIELDS.ATTACHMENTS]: value => ({
      [AGENCY_FIELDS.ATTACHMENTS]: value && typeof value.toJS === 'function' ? value.toJS() : [],
    }),
    default: (value, key) => ({ [key]: value === null || value === undefined ? '' : value }),
  }

  const handleInitialState = useCallback(
    payload => {
      const data = payload || (agency?.toJS() ?? {})

      const agencyFieldsNamesList = Object.values(AGENCY_FIELDS)

      const newValues = Object.entries(data).reduce((acc, [key, value]) => {
        if (!agencyFieldsNamesList.includes(key)) {
          return acc
        }
        const transformation = valueTransformations[key] || valueTransformations.default

        return { ...acc, ...transformation(value, key) }
      }, {})

      setFieldValues({
        ...newValues,
        agencyCreationDate: agency ? formatDateDays(agency.agencyCreationDate) : null,
      })
    },
    [agency, setFieldValues]
  )

  const handleGetAgencyInfo = useCallback(() => {
    if (agencyId && !gettingAgency) {
      setGettingAgency(true)
      dispatch(getAgencyFull(agencyId))
        .then(response => {
          handleInitialState(response)
          setGettingAgency(false)
        })
        .catch(() => {
          showToast({
            message: 'Erro ao buscar dados da intermediária.',
            type: 'error',
          })
          navigate(ROUTES.MANAGE_AGENCIES)
          setGettingAgency(false)
        })
    }
  }, [agencyId, gettingAgency])

  useEffect(() => {
    handleGetAgencyInfo()
  }, [agencyId])

  useEffect(() => {
    dispatch(
      getConnetionMessageTool({
        purpose: 'seller',
      })
    )
  }, [])

  const handleSave = useCallback(() => {
    setTouchedAll()
    const newAttachments = attachments.filter(item => item?.id === undefined)

    if (newAttachments) {
      values.agencyAttachments = newAttachments
    }
    if (Object.values(errors).length) {
      return
    }

    const responsibleRefera = values?.responsibleRefera?.map(item => {
      const sector = Array.isArray(item?.sector)
        ? item?.sector.map(s => (typeof s === 'object' && s !== null ? s.id : s))
        : [item?.sector?.id || item?.sector]
      return {
        user: item?.id,
        acceptHelpRequestData: item?.acceptHelpRequestData,
        sector,
      }
    })

    const newValues = { ...values, responsibleRefera }

    setIsSubmitting(true)
    if (agencyId) {
      if (
        checkIfUserDoesNotPermission(PERMISSIONS.INTERMEDIARY_CONFIGS, [
          PERMISSIONS_ACTIONS.ADD,
          PERMISSIONS_ACTIONS.EDIT,
        ])
      ) {
        setIsSubmitting(false)
        return
      }
      if (toDelete.length > 0) {
        toDelete.map(fileID => dispatch(deleteAgencyContract(fileID)))
        setToDelete([])
      }
      dispatch(updateAgency({ ...newValues, updateOldBudgets: false }))
        .then(data => {
          handleInitialState(data)
          handleEdit()
          showToast({
            type: 'success',
          })
          setIsSubmitting(false)
        })
        .catch(() => {
          handleEdit()
          showToast({
            type: 'error',
          })
          setIsSubmitting(false)
        })
      return
    }

    if (checkIfUserDoesNotPermission(PERMISSIONS.INTERMEDIARY_CONFIGS, PERMISSIONS_ACTIONS.ADD)) {
      setIsSubmitting(false)
      return
    }

    dispatch(createAgency(newValues))
      .then(data => {
        navigate(`${ROUTES.AGENCY}/${data.id}`)
        showToast({
          type: 'success',
        })
        handleEdit()
      })
      .catch(() => {
        handleEdit()
        showToast({
          type: 'error',
        })
        setIsSubmitting(false)
      })
  }, [agencyId, dispatch, errors, setTouchedAll, values, attachments, toDelete])

  const handleNotSave = useCallback(() => {
    if (agencyId) {
      handleInitialState()
      handleEdit()
      return
    }
    navigate(ROUTES.MANAGE_AGENCIES)
  }, [agencyId, handleEdit, handleInitialState])

  const handleBackNavigation = useCallback(() => {
    if (agencyId && isRefera) {
      navigate(ROUTES.MANAGE_AGENCIES)
      return
    }
    navigate(-1)
  }, [agencyId, isRefera])

  const handleGoToClassificationList = useCallback(() => {
    navigate(`${ROUTES.AGENCY}/${agencyId}${ROUTES.CLASSIFICATION_LIST}`)
  }, [agencyId])

  const handleGoToVIPClientesList = useCallback(() => {
    navigate(`${ROUTES.AGENCY}/${agencyId}${ROUTES.VIP_CLIENTS_LIST}`)
  }, [agencyId])

  const handleAttachmentsChange = useCallback(newAttachments => {
    setAttachments(newAttachments?.contracts)
    setToDelete(newAttachments?.toDelete)
  }, [])

  if (gettingAgency || isBanksLoading) {
    return <Loading />
  }

  return (
    <Grid className={styles.container}>
      <Grid container justifyContent="space-between" alignItems="center" className={styles.save}>
        <Grid item>
          <IconButton
            buttonClassName={styles.navigate}
            aria-label="Voltar página"
            onClick={handleBackNavigation}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        {!isEditing ? (
          <Grid item>
            <Grid container alignItems="center" spacing={8}>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setIsEmailProviderModalOpen(true)}
                >
                  Email
                </Button>
              </Grid>
              {isAdmin && (
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setIsReferaDetailsModalOpen(true)}
                  >
                    Dados da Refera
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button color="primary" variant="contained" onClick={handleGoToClassificationList}>
                  Classificações especiais
                </Button>
              </Grid>
              <Grid item>
                <Button color="primary" variant="contained" onClick={handleGoToVIPClientesList}>
                  Clientes VIP
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={handleEdit} className={styles.buttonEdit} variant="outlined">
                  Editar informações
                  <Svg className={styles.buttonEditIcon} type={ICON.EDIT} />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item>
            <Grid container alignItems="center" spacing={8}>
              <Grid item>
                <Typography color="primary">Deseja salvar?</Typography>
              </Grid>
              <Grid item>
                <Button
                  className={styles.button}
                  startIcon={<CheckIcon />}
                  color="primary"
                  variant="contained"
                  onClick={handleSave}
                  isLoading={isSubmitting}
                >
                  Sim
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={handleNotSave}
                  startIcon={<CloseIcon />}
                  color="primary"
                  variant="outlined"
                  disabled={isSubmitting}
                >
                  Não
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid className={`${styles.container} ${styles.containerWithColumns}`}>
        <AsideProfile
          profileName={AGENCY_FIELDS.BRAND_LOGO_PNG_URL}
          {...formHandleValues}
          handleEdit={handleEdit}
          isEditing={isEditing}
        />

        <Grid className={styles.cards}>
          {/*  BYPASS ACCORDEON */}
          {showBusinessParameters && <AgencyBypass isEditing={isEditing} {...formHandleValues} />}
          {/* ----------------- */}

          {modifiedAgencyViewValues.map(value => (
            <Accordion
              hideEditButton
              isEditing={isEditing}
              key={value.id}
              {...value}
              {...formHandleValues}
            />
          ))}
          <AddAttachments
            agency={agency}
            isEditing={!isIntermediary && (isAdmin ? isEditing : !isEditing)}
            key="agencyAttachments"
            {...formHandleValues}
            onChange={handleAttachmentsChange}
          />

          <AccordionAddUser
            agency={agency}
            isCreating={!agency?.id}
            staffUsers={agency?.staffUsers?.valueSeq()?.toArray()}
            setFieldValue={setFieldValue}
          />
          {!isIntermediary && <AddCsrefera isEditing={isEditing} setValue={setFieldValue} />}
        </Grid>
        <EmailModal
          open={isEmailProviderModalOpen}
          onClose={() => setIsEmailProviderModalOpen(false)}
        />
        {isReferaDetailsModalOpen && (
          <AgencyReferaDetailsModal
            open={isReferaDetailsModalOpen}
            onClose={() => setIsReferaDetailsModalOpen(false)}
            handleGetAgencyInfo={handleGetAgencyInfo}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default Agency
